/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";

let ps = null;

export default function AboutUs() {
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.body.classList.toggle("profile-page");
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        if (ps != null) {
          ps.destroy();
        }

        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
      document.body.classList.toggle("profile-page");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header">
          <img
            alt="..."
            className="dots"
            src={require("assets/img/dots.png").default}
          />
          <img
            alt="..."
            className="path"
            src={require("assets/img/path4.png").default}
          />
          <Container className="align-items-center" style={{paddingTop:'140px'}}>
            <Row>
            <Col lg="6" md="6" style={{marginBottom:'20px'}}>
                <h1>Jacques Bracke</h1>
                {/* <h5 className="text-on-back">CEO</h5> */}
                <p className="profile-description">
                  <br></br>
                  Co-Founder<br></br>
                  Software Engineer and Back-End developer<br></br>
                  <br></br>
                  Having completed a BSc in Computer Science and Informatics
                  Honors (Cum Laude), he is highly competitive in everything he
                  does. The exponential growth of the IT industry as well as the
                  cost involved to start-up in the digital world, makes it a
                  challenge for many business ideas to grow. We offer a solution
                  with the best skill sets available to kickstart your company.
                </p>
              </Col>
              <Col lg="6" md="6" style={{marginBottom:'20px'}}>
                <h1>Our Vision</h1>
                <p className="profile-description">
                <br></br>
                  Our vision is to be able to allow for the inventors out there,
                  who may lack the high capital needed to start there business
                  to begin their venture.<br></br>
                  We want to create a business with you and create a team
                  together in which we can grow the business to its maximum
                  capabilities.<br></br>
                  We want you to think of us as part of your idea, as team members,
                  Being able to grow an idea into reality and beyond what was thought of before.
                </p>     
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
}
