/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import emailjs from "emailjs-com";
import { init } from "emailjs-com";
import {
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardTitle,
  UncontrolledAlert,
  Container,
  Row,
} from "reactstrap";
import { Col, Form, Button } from "react-bootstrap";
// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";

export default function ContactUs() {
  init("user_BFBfGtrMEV8ehtwVOFWT1");
  const initialState = {
    firstname: "",
    lastname: "",
    email: "",
    message: "",
  };

  const initialView = {
    showMessageSent: false,
  };

  const [viewData, setViewData] = useState(initialView);
  const [formData, setFormData] = useState(initialState);
  const [validated, setValidated] = useState(false);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      setValidated(false);
      setFormData({
        ...formData,
        firstname: "",
        lastname: "",
        email: "",
        message: "",
      });
      setViewData({ ...viewData, showMessageSent: true });
      emailjs
        .sendForm(
          "service_yfkn4x4",
          "template_534gvho",
          form,
          "user_BFBfGtrMEV8ehtwVOFWT1"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  };

  const [squares1to6, setSquares1to6] = React.useState("");
  const [squares7and8, setSquares7and8] = React.useState("");
  React.useEffect(() => {
    document.body.classList.toggle("register-page");
    document.documentElement.addEventListener("mousemove", followCursor);
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("register-page");
      document.documentElement.removeEventListener("mousemove", followCursor);
    };
  }, []);
  const followCursor = (event) => {
    let posX = event.clientX - window.innerWidth / 2;
    let posY = event.clientY - window.innerWidth / 6;
    setSquares1to6(
      "perspective(500px) rotateY(" +
        posX * 0.05 +
        "deg) rotateX(" +
        posY * -0.05 +
        "deg)"
    );
    setSquares7and8(
      "perspective(500px) rotateY(" +
        posX * 0.02 +
        "deg) rotateX(" +
        posY * -0.02 +
        "deg)"
    );
  };
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header">
          <div className="page-header-image" />
          <div className="content">
            <Container>
              <Row>
                <Col className="" lg="8" md="6">
                  <div
                    className="square square-7"
                    id="square7"
                    style={{ transform: squares7and8 }}
                  />
                  <div
                    className="square square-8"
                    id="square8"
                    style={{ transform: squares7and8 }}
                  />
                  <Card className="card-register">
                    <CardHeader>
                      <CardImg
                        alt="..."
                        style={{ marginLeft: "-17px" }}
                        src={require("assets/img/square-purple-1.png").default}
                      />
                      <CardTitle tag="h4" style={{ marginLeft: "15px" }}>
                        Contact
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                        netlify-honeypot="bot-field"
                        data-netlify="true"
                        name="contact"
                      >
                        <input type="hidden" name="form-name" value="contact" />
                        <Form.Row>
                          <Form.Group
                            as={Col}
                            lg="6"
                            controlId="formGroupEmail"
                          >
                            <Form.Label>1. First name *</Form.Label>
                            <Form.Control
                              required
                              name="firstname"
                              onChange={onChange}
                              value={formData.firstname}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter your first name.
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            lg="6"
                            controlId="formGroupEmail"
                          >
                            <Form.Label>2. Last name *</Form.Label>
                            <Form.Control
                              required
                              name="lastname"
                              value={formData.lastname}
                              onChange={onChange}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter your last name.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col} controlId="formGroupEmail">
                            <Form.Label>3. Email *</Form.Label>
                            <Form.Control
                              required
                              onChange={onChange}
                              type="email"
                              value={formData.email}
                              name="email"
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter your email.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col} controlId="formGroupEmail">
                            <Form.Label>4. Message </Form.Label>
                            <Form.Control
                              onChange={onChange}
                              as="textarea"
                              value={formData.message}
                              rows="4"
                              name="message"
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter your email.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>

                        <Form.Group controlId="formGroupEmail">
                          <Button
                            className="btn-round"
                            type="submit"
                            color="primary"
                            size="lg"
                          >
                            Let's do this!
                          </Button>
                        </Form.Group>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {viewData.showMessageSent && (
                <UncontrolledAlert
                  style={{ maxWidth: "664px" }}
                  className="alert-with-icon"
                  color="primary"
                >
                  <span data-notify="icon" className="tim-icons icon-coins" />
                  <span>
                    <b>Sent! -</b>
                    Message Sent, We will be in contact with you shortly
                  </span>
                </UncontrolledAlert>
              )}

              <div className="register-bg" />
              <div
                className="square square-1"
                id="square1"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-2"
                id="square2"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-3"
                id="square3"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-4"
                id="square4"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-5"
                id="square5"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-6"
                id="square6"
                style={{ transform: squares1to6 }}
              />
            </Container>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
